import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import siteAdminGroupListConfig from 'modules/siteAdmin/views/siteAdminGroupListConfig'

class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardSiteAdminGroupListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardSiteAdminGroupListConfig = siteAdminGroupListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardSiteAdminGroupListConfig.search,
      filter: this.standardSiteAdminGroupListConfig.filter,
      selectedData: this.standardSiteAdminGroupListConfig.selectedData,
      displayMode: this.standardSiteAdminGroupListConfig.displayMode,
      displayModeTypes: this.standardSiteAdminGroupListConfig.displayModeTypes,
      sort: this.standardSiteAdminGroupListConfig.sort,
      card: this.standardSiteAdminGroupListConfig.card,
      table: this.standardSiteAdminGroupListConfig.table,
    }
  }
}

export default new listConfig()
